import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RxArrowDown } from "react-icons/rx";
import { RxArrowUp } from "react-icons/rx";

import { LOCAL_ROUTES } from "../../Utils/enums";
import TableSearchBar from "../TableSearchBar";
import { sortList } from "../../Utils/sortFunction";
import { SortFieldsType } from "../../Types/GeneralTypes";

interface FolderBarProps {
  loading: boolean;
  childFolders: any[];
}

const ChildFolderTable: React.FC<FolderBarProps> = ({
  loading,
  childFolders
}) => {
  const [folderList, setFolderList] = useState<any[]>([])
  const [searchText, setSearchText] = useState("")
  const [sortFields, setSortField] = useState<SortFieldsType>({
    id: false,
    name: false,
    total: false,
    pass: false,
    fail: false,
    skip: false,
    missing: false
  })

  const navigate = useNavigate();

  const handleSearchTextChange = (e: any) => {
    const value = e.target.value
    setSearchText(value)
    if (value.length > 0) {
      const issues = childFolders.filter((i) => i.name.toLowerCase().includes(value.toLowerCase()))
      setFolderList(issues)
    } else {
      setFolderList(childFolders)
    }
  }

  const handleSorting = (sortField: string, sortOrder: boolean) => {
    if (sortField) {
      const sorted = sortList(folderList, sortField, sortOrder)
      setFolderList(sorted);
    }
  };

  function fieldInSortField(field: any): field is keyof typeof sortFields {
    return Object.keys(sortFields).includes(field);
  }

  const handleFieldSort = (field: string) => {
    if (fieldInSortField(field)) {
      setSortField((prvs: SortFieldsType) => {
        const updatedSortFields = { ...prvs }
        updatedSortFields[field] = updatedSortFields[field] ? false : true
        return updatedSortFields
      })

      handleSorting(field, sortFields[field])
    }
  }


  useEffect(() => {
    setFolderList(childFolders.map(item => {
      item.name = item.name.replace(/[0-9]\) | \(x\)|x\)/g, "").trim()
      return item
    })
    )
    setSearchText("")
  }, [childFolders])


  if (loading) {
    return (
      <div className="rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 w-[93.2vw]">
        <div className="px-6 py-24 text-center">
          <div
            className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 w-[93.2vw]">
      <TableSearchBar placeholder="Search Folder" value={searchText} onChange={handleSearchTextChange} />
      {folderList.length > 0 ? (
        <div className="table-container">
          <table className="w-full">
            <thead>
              <tr className="underline underline-offset-8 sticky top-0 bg-white">
                <th className="text-left">
                  <span className="pr-1">Folder Name</span>
                  <button
                    className='h-fit w-fit'
                    onClick={() => { handleFieldSort("name") }}
                  >{sortFields.name ? <RxArrowDown /> : <RxArrowUp />}</button>
                </th>
                <th className="p-2.5 xl:p-5 text-center">
                  <span>Total Count</span>
                  <button
                    className='h-fit w-fit'
                    onClick={() => { handleFieldSort("total") }}
                  >{sortFields.total ? <RxArrowDown /> : <RxArrowUp />}</button>
                </th>
                <th className="p-2.5 xl:p-5 text-center">
                  <span>Passed</span>
                  <button
                    className='h-fit w-fit'
                    onClick={() => { handleFieldSort("pass") }}
                  >{sortFields.pass ? <RxArrowDown /> : <RxArrowUp />}</button>
                </th>
                <th className="p-2.5 xl:p-5 text-center">
                  <span>Failed</span>
                  <button
                    className='h-fit w-fit'
                    onClick={() => { handleFieldSort("fail") }}
                  >{sortFields.fail ? <RxArrowDown /> : <RxArrowUp />}</button>
                </th>
                <th className="p-2.5 xl:p-5 text-center">
                  <span>Skipped</span>
                  <button
                    className='h-fit w-fit'
                    onClick={() => { handleFieldSort("skip") }}
                  >{sortFields.skip ? <RxArrowDown /> : <RxArrowUp />}</button>
                </th>
                <th className="p-2.5 xl:p-5 text-center">
                  <span>Missing</span>
                  <button
                    className='h-fit w-fit'
                    onClick={() => { handleFieldSort("missing") }}
                  >{sortFields.missing ? <RxArrowDown /> : <RxArrowUp />}</button>
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {folderList.map((item: any, index: number) => (
                <tr className="border-b border-[#eee]" key={index}>
                  &nbsp;
                  <td className="flex items-center">
                    <span
                      className="cursor-pointer"
                      onClick={() => navigate(`${LOCAL_ROUTES.PIPELINE_VIEW}${item.id}`)}>
                      {item?.name}
                    </span>
                  </td>
                  <td className="p-2.5 xl:p-5 text-center">
                    {item?.total}
                  </td>
                  <td className="p-2.5 xl:p-5 text-center">
                    {item?.pass}
                  </td>
                  <td className="p-2.5 xl:p-5 text-center">
                    {item?.fail}
                  </td>
                  <td className="p-2.5 xl:p-5 text-center">
                    {item?.skip}
                  </td>
                  <td className="p-2.5 xl:p-5 text-center">
                    {item?.missing}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="px-6 py-24 text-center" style={{ fontWeight: "bold" }}>
          <h1 className="text-bold font-serif">No folders {searchText.length && <>with name <u className="text-lg">"{searchText}"</u></>} found ☹️ !!</h1>
        </div>
      )}
    </div>
  );
};

export default ChildFolderTable;
