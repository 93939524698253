export const sortList = (list: any[], sortField: string, sortOrder: boolean) => {
    return [...list].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
            a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                numeric: true,
            }) * (!sortOrder ? 1 : -1)
        );
    });
}