import React, {useCallback, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import axios from "axios";
import Modal from "../Libraries/Modal/CustomModal";
//Custom Hooks
import withLogout from "../AutoLogOut/withLogout";
//Components
import LoadingIndicator from "../Components/LoadingIndicator";
import Navbar from "../Libraries/Navbar";
import SearchBar from "../Components/SearchBar";
import CardComponent from "../Components/CardComponent";
import ChildFolderTable from "../Components/Tables/ChildFolderTable";
import IssueTable from "../Components/Tables/IssueTable";
//Contants
import {LOCAL_ROUTES} from "../Utils/enums";
//Types
import {
  folderDetailsResponse,
  FolderNameResponse,
} from "../Types/ApiResponseTypes";
import {BreadcrumbMenuItem} from "../Types/GeneralTypes";
import BreadcrumbMenu from "../Components/BreadcrumbMenu";

interface PipelineViewProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const DynamicPipelineView: React.FC<PipelineViewProps> = ({
  setIsAuthenticated,
}) => {
  const {folderId} = useParams<{folderId: string}>();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState<folderDetailsResponse>({
    total: 0,
    pass: 0,
    fail: 0,
    skip: 0,
    missing: 0,
    name: "",
    id: "",
    childFolders: [],
    childIssues: [],
  });
  const [filteredApiData, setFilteredApiData] = useState<folderDetailsResponse>(
    {
      total: 0,
      pass: 0,
      fail: 0,
      skip: 0,
      missing: 0,
      name: "",
      id: "",
      childFolders: [],
      childIssues: [],
    },
  );
  const [filter, setFilter] = useState("total");
  const [folderList, setFolderList] = useState<FolderNameResponse[]>([]);
  const [breadcrumbMenu, setBreadcrumbMenu] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {state} = useLocation();

  const apiUrl = process.env.REACT_APP_API_URL;
  const date = sessionStorage.getItem("Pipeline Date");

  //Variables
  const token = sessionStorage.getItem("Access");
  const dashboardUrl = `${LOCAL_ROUTES.DASHBOARD_VIEW}#access_token=${token}`;
  const nightlyTestviewUrl = LOCAL_ROUTES.NIGHTLY_TEST_VIEW;
  const pipelineviewUrl = LOCAL_ROUTES.PIPELINE_VIEW;

  //Util Function
  const generateBreadcrumbMenu = (
    prvs: BreadcrumbMenuItem[],
    folders: any[] = [],
  ) => {
    let breadcrumbMenuItems: BreadcrumbMenuItem[] = [];
    if (!prvs.length) {
      breadcrumbMenuItems = [
        {name: "Home", url: dashboardUrl},
        {name: "Nightly Test", url: `${nightlyTestviewUrl}`},
      ];
    } else {
      breadcrumbMenuItems = [...prvs];
      const parentFolderIndex = breadcrumbMenuItems.findIndex(item => {
        if (item.id) {
          return item.id == folderId;
        }
        return false;
      });

      if (parentFolderIndex !== -1) {
        breadcrumbMenuItems = breadcrumbMenuItems.slice(0, parentFolderIndex);
      }
    }

    folders.forEach(folder => {
      breadcrumbMenuItems.push({
        name: folder.name,
        url: `${pipelineviewUrl}${folder.id}`,
        id: folder.id,
      });
    });

    return breadcrumbMenuItems;
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const filteredApiDate = {
      ...apiData,
      childFolders: filterChildFolders(),
      childIssues: filteredChildIssues(),
    };
    setFilteredApiData(filteredApiDate);
  }, [filter, apiData]);

  const filterChildFolders = () => {
    return apiData.childFolders.filter(folder => {
      return folder[filter] > 0;
    });
  };

  const filteredChildIssues = () => {
    return apiData.childIssues.filter(issue => {
      return issue[filter] > 0;
    });
  };

  const fetchFoldersDataPipeLineView = useCallback(async () => {
    if (!token || !date || !folderId) return;
    const C_SEARCHRESULT = "Search Result";

    try {
      setIsLoading(true);
      const headers = {
        Authorization: token,
        Accept: "*/*",
      };

      const response = await axios.get(
        `${apiUrl}/tests/folders/${folderId}?type=NIGHTLY&nightlyDate=${date}`,
        {headers},
      );
      setApiData(response.data);

      const newFolderList: any[] = [];
      let name = response.data.name.replace(/[0-9]\) | \(x\)|x\)/g, "");
      if (state && state.isSearchResult) {
        name = C_SEARCHRESULT + " : " + name;
      }
      newFolderList.push({id: folderId, name: name});
      setBreadcrumbMenu((prvs: BreadcrumbMenuItem[]) =>
        generateBreadcrumbMenu(prvs, newFolderList),
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  }, [token, date, apiUrl, folderId]);

  //Effects
  useEffect(() => {
    void fetchFoldersDataPipeLineView();
  }, [fetchFoldersDataPipeLineView]);

  useEffect(() => {
    const getFoldersList = async () => {
      try {
        const tokenFolderDataModel = await sessionStorage.getItem("Access");
        const headers = {
          Authorization: tokenFolderDataModel,
          Accept: "*/*",
        };
        const response = await axios.get(`${apiUrl}/folders`, {headers});
        setFolderList(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    getFoldersList();
  }, []);

  const graphData = [{}];

  const handleModalClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{overflowX: "hidden"}}>
      <Navbar isOpen={isOpen} onToggle={handleToggle} />
      <LoadingIndicator isLoading={isLoading} />
      <div
        className={`flex h-screen w-[120vw] p-8 ${isLoading ? "opacity-20 cursor-not-allowed" : "cursor-pointer"}`}>
        <div className="w-4/5 bg-white -p-[0.5rem]">
          <button
            className="bg-black text-white px-4 py-2 rounded-full float-right mt-1.5"
            onClick={handleModalClick}>
            View Historic Data
          </button>
          &nbsp; &nbsp;
          <BreadcrumbMenu list={breadcrumbMenu} />
          <br />
          <SearchBar folders={folderList} />
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold font-serif">
              Summary for{" "}
              {filteredApiData.name.replace(/[0-9]\) | \(x\)|x\)/g, "")} for{" "}
              {date}
            </h2>
          </div>
          <CardComponent
            apiData={filteredApiData}
            setFilter={setFilter}
            currentFilter={filter}
          />
          <br />
          {filteredApiData.childFolders.length > 0 && (
            <ChildFolderTable
              loading={isLoading}
              childFolders={filteredApiData.childFolders}
            />
          )}
          <br />
          {filteredApiData.childIssues.length > 0 && (
            <IssueTable
              childIssues={filteredApiData.childIssues}
              tableName={filteredApiData.name.replace(
                /[0-9]\) | \(x\)|x\)/g,
                "",
              )}
              loading={isLoading}
              itemsPerPage={20}
            />
          )}
          <br />
          <Modal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            graphData={graphData}
          />
        </div>
      </div>
    </div>
  );
};

export default withLogout(DynamicPipelineView);
