import React from "react";

interface AutoLogoutPopupProps {
  onConfirm: () => void;
}

const AutoLogoutPopup: React.FC<AutoLogoutPopupProps> = ({onConfirm}) => {
  return (
    <div className="popup fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="flex flex-col items-center justify-center bg-white">
        <h1 className="text-5xl text-center mb-4">
          Your session has expired. You have been logged out.
        </h1>
        <br />
        <p className="text-2xl text-center mb-8">
          Please click the button below to log in again
        </p>
        <button
          className="bg-black text-white py-2 px-8 rounded-lg cursor-pointer"
          onClick={onConfirm}>
          Login
        </button>
      </div>
    </div>
  );
};

export default AutoLogoutPopup;
