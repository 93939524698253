import React from "react";
import {Navigate, Outlet} from "react-router-dom";

interface IProtectedRoute {
  isAuthenticated: boolean;
}

const ProtectedRoutes: React.FC<IProtectedRoute> = ({isAuthenticated}) => {
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};
export default ProtectedRoutes;
