import React, {useEffect, useState} from "react";
import SearchResults from "./SearchResults";

type Props = {
  folders: {
    id: string;
    name: string;
  }[];
};

const SearchBar: React.FC<Props> = ({folders}) => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(folders.length > 0 ? false : true);
  }, [folders]);

  const handleSearchChange = (e: any) => {
    const value = e.target.value;
    setSearchText(value);
  };

  return (
    <div className="w-full min-h-[50px] relative p-2 rounded-md focus-within:scale-[1.01] focus-within:z-10 ease-in-out ">
      <div className="bg-gray-100 w-full rounded-md absolute top-0 left-0 focus-within:p-1 focus-within:shadow-lg max-h-[400px] overflow-hidden">
        <input
          className="w-full border-2 outline-none rounded-md p-2 peer/searchbox pointer-events-auto "
          type="search"
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Search"
        />
        {
          <SearchResults
            folders={folders}
            searchText={searchText}
            isLoading={isLoading}
          />
        }
      </div>
    </div>
  );
};

export default SearchBar;
