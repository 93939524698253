import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {useNavigateToDashboard} from "../CustomHooks/NavigationHooks";
import LoadingIndicator from "../Components/LoadingIndicator";
import Navbar from "../Libraries/Navbar";
import axios from "axios";
import Graph from "../Libraries/GraphicalComponent/Graph";
import {LOCAL_ROUTES} from "../Utils/enums";

interface NightlyTestProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const NightlyTest: React.FC<NightlyTestProps> = ({setIsAuthenticated}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cardData, setCardData] = useState<any[]>([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const location = useLocation();
  const {handleNavigateToDashboard} = useNavigateToDashboard(location.pathname);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const token = sessionStorage.getItem("Access");
  const dashboardUrl = `${LOCAL_ROUTES.DASHBOARD_VIEW}#access_token=${token}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: token,
          Accept: "*/*",
        };
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 9);
        const response = await axios.get(
          `${apiUrl}/tests/report?type=NIGHTLY&startDate=${startDate.toISOString().split("T")[0]}&endDate=${endDate.toISOString().split("T")[0]}`,
          {headers},
        );
        const cardData = response.data.data.map((item: any) => ({
          date: item.reportDate,
          total: item.total,
          pass: item.pass,
          fail: item.fail,
        }));
        setCardData(cardData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [apiUrl, token]);

  const handleCardClick = (date: string, folderId: any) => {
    sessionStorage.setItem("Pipeline Date", date);
    navigate(`${LOCAL_ROUTES.PIPELINE_VIEW}${folderId}#`);
  };

  return (
    <div style={{overflowX: "hidden"}}>
      <Navbar isOpen={isOpen} onToggle={handleToggle} />
      <LoadingIndicator isLoading={isLoading} />
      <div
        className={`flex h-screen w-[120vw] p-8 ${isLoading ? "opacity-20 cursor-not-allowed" : ""}`}>
        <div className="w-4/5 bg-white -p-[0.5rem]">
          <div className="flex items-center text-gray-500 text-sm font-serif">
            <a
              href={dashboardUrl}
              className="mr-2 hover:text-gray-700"
              onClick={handleNavigateToDashboard}>
              Home
            </a>
            <span className="mr-2">{">"}</span>
            <a
              href="#"
              className={`mr-2 hover:text-gray-700 ${location.pathname === LOCAL_ROUTES.NIGHTLY_TEST_VIEW ? "font-bold text-black" : "text-gray-700"}`}>
              Nightly Test
            </a>
          </div>
          <br />
          <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold font-serif mb-4">Nightly Test</h1>
            <br />
            <div className="grid grid-cols-5 gap-5">
              {cardData.slice(0, 10).map((card: any, index: number) => (
                <div
                  key={card.date}
                  className={`border border-gray-300 rounded-lg shadow p-4 cursor-pointer ${selectedDate === card.date ? "bg-blue-50" : ""}`}
                  onClick={() => handleCardClick(card.date, -1)}>
                  <h2 className="text-lg font-medium mb-2">
                    Report Date: {card.date}
                  </h2>
                  <p>Total Count: {card.total}</p>
                  <p>Pass Count: {card.pass}</p>
                  <p>Fail Count: {card.fail}</p>
                </div>
              ))}
            </div>
            <Graph cardData={cardData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NightlyTest;
