import React from "react";
import SessionTimeout from "./SessionTimeout";

/* This HOC takes one argument: the component to be wrapped.
  It returns a new component that renders the Logout component and passes the Component as a child. */
function withLogout(MyComponent: React.FC<any>) {
  const onTimeout = () => {
    sessionStorage.clear();
  };
  return (props: any) => (
    <SessionTimeout timeoutInMinutes={60} onSessionTimeout={onTimeout}>
      <MyComponent {...props} />
    </SessionTimeout>
  );
}

export default withLogout;
