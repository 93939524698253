import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Fuse, { FuseResult } from "fuse.js";
import axios from "axios";
import { useNavigateToSearchPipeline } from "../CustomHooks/NavigationHooks";

type Item = {
  id: string;
  name: string;
};
type SearchResultsProps = {
  folders: Item[] | [];
  searchText: string;
  isLoading: boolean
};
type DisplayFolderResultsProps = {
  filterdResult: FuseResult<Item>[] | Item[] | [];
  searchText: string;
};
type DisplayIdResultsProps = {
  searchText: string;
};
type ResultProp = {
  isId?: boolean;
  item: FuseResult<Item> | Item;
  searchText: string;
};

const Result = ({ isId = false, item, searchText }: any) => {
  const navigate = useNavigateToSearchPipeline();
  if (isId) {
    return (
      <a
        onClick={e => {
          e.preventDefault();
          navigate(item.parentId);
        }}
        href={item.parentId}
        className="pt-2 pb-2 pl-4 pr-4 border-2 text-center rounded-full hover:bg-slate-300 text-nowrap"
        target="_blank"
        title={item.parentId}
      >
        {item?.key}
      </a>
    );
  } else {
    const res = item.item;
    const index = res?.name.toLowerCase().indexOf(searchText.toLowerCase());
    const text =
      index != undefined && index >= 0 ?
        (
          <>
            {res?.name.slice(0, index)}
            <b className="bg-orange-200">
              {res?.name.slice(index, index + searchText.length)}
            </b>
            {res?.name.slice(index + searchText.length)}
          </>
        ) :
        (
          res?.name
        );

    return (
      <a
        onClick={e => {
          e.preventDefault();
          navigate(res.id);
        }}
        href={res.id}
        className="pt-2 pb-2 pl-4 pr-4 border-2 text-center rounded-full hover:bg-slate-300 text-nowrap"
        title={res.id}
      >
        {text}
      </a>
    );
  }
};

const DisplayIdResults = ({ searchText }: DisplayIdResultsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [issueList, setIssueList] = useState<Item[]>([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    let id = searchText.slice(4);
    const controller = new AbortController()
    const fetchIssues = async () => {
      setIsLoading(true);
      if (id.length) {
        try {
          const tokenFolderDataModel = await sessionStorage.getItem("Access");
          const headers = {
            Authorization: tokenFolderDataModel,
            Accept: "*/*",
          };
          const response = await axios.get(
            `${apiUrl}/issues/${searchText}`,
            {
              headers,
              signal: controller.signal
            },
          );
          setIssueList(response.data.data);
          setIsLoading(false);
        } catch (err) {
          console.error(err);
        }
      }
    };
    const tOutId = setTimeout(() => {
      fetchIssues();
    }, 500)
    return () => {
      //cancel request using abort signal -- fast typing
      clearTimeout(tOutId)
      controller.abort()
    };
  }, [searchText]);

  if (isLoading) {
    return <p>loading....</p>;
  }
  return (
    <>
      {issueList.length > 0
        ? issueList.map(item => (
          <Result key={item.id} searchText={searchText} isId={true} item={item} />
        )) :
        "No Result Found"}
    </>
  );
};

const DisplayFolderResults = ({
  filterdResult,
  searchText,
}: DisplayFolderResultsProps) => {
  return (
    <>
      {filterdResult.length > 0 ?
        (
          filterdResult
            .slice(0, 5)
            .map((item, index) => <Result key={index} item={item} searchText={searchText} />)
        ) :
        (
          <p className="rounded-md">No Result Found</p>
        )}
    </>
  );
};

function SearchResults({ folders, searchText, isLoading }: SearchResultsProps) {
  let id = false;
  const regex = /^TCS-/;
  let filterdResult: FuseResult<Item>[] = [];
  if (regex.test(searchText)) {
    id = true;
  } else {
    if (searchText) {
      const fuse = new Fuse(folders, {
        isCaseSensitive: false,
        keys: ["name"],
      });
      filterdResult = fuse.search(searchText);
    }
  }

  return (
    <div className="w-full hidden peer-focus/searchbox:block hover:block max-h-[350px]">
      <div className="flex w-full pt-4 pb-4 pl-1 pr-1 gap-3 flex-wrap overflow-x-hidden overflow-y-auto max-h-[350px]">
        {
          id ?
            <DisplayIdResults searchText={searchText} />
            :
            isLoading ?
              <p>Loafing...</p>
              :
              <DisplayFolderResults
                searchText={searchText}
                filterdResult={filterdResult}
              />
        }
      </div>
    </div>
  );
}

export default SearchResults;
