import React, {useCallback, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {BreadcrumbMenuItem} from "../Types/GeneralTypes";
import {useNavigateToDashboard} from "../CustomHooks/NavigationHooks";

type Props = {
  list: BreadcrumbMenuItem[];
};

const BreadcrumbMenu: React.FC<Props> = ({list}) => {
  const location = useLocation();
  const {handleNavigateToDashboard} = useNavigateToDashboard(location.pathname);

  return (
    <div
      dir="rtl"
      className="flex items-center text-nowrap text-gray-500 text-sm max-w-[80%] w-fit overflow-hidden">
      <div dir="ltr">
        {list.map((breadcrumb: any, index: any) => (
          <React.Fragment key={breadcrumb.url + index}>
            <Link
              to={breadcrumb.url}
              className={`mr-2 hover:text-gray-700 font-serif scroll-mr-96 ${
                index === list.length - 1 ? "font-bold text-black" : ""
              }`}
              onClick={
                index === list.length - 1
                  ? undefined
                  : handleNavigateToDashboard
              }>
              {breadcrumb.name}
            </Link>
            {index < list.length - 1 && <span className="mr-2">{">"}</span>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default BreadcrumbMenu;
