import React, {useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
} from "chart.js";
import {useLocation, useParams} from "react-router-dom";
import axios from "axios";
import LoadingIndicator from "../../Components/LoadingIndicator";

interface IGraph {
  cardData: any;
}

ChartJS.register(
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
);

const Graph: React.FC<IGraph> = ({cardData}) => {
  const date: any = sessionStorage.getItem("Pipeline Date");
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = sessionStorage.getItem("Access");
  const {folderId} = useParams<{folderId: string}>();
  const [isLoading, setIsLoading] = useState(true);
  const [chartsData, setChartsData]: any = useState({
    labels: [],
    datasets: [
      {
        label: "Pass",
        data: [],
        borderColor: "green",
      },
      {
        label: "Fail",
        data: [],
        borderColor: "red",
      },
    ],
  });

  const [endDate, setEndDate] = useState(new Date(date));
  const [dateRange, setDateRange] = useState(7);
  const [startDate, setStartDate] = useState(
    new Date(endDate.getTime() - dateRange * 24 * 60 * 60 * 1000),
  );

  const handleDateRangeChange = (event: {target: {value: any}}) => {
    const selectedDateRange = event.target.value;
    setDateRange(selectedDateRange);
    const newStartDate = new Date(
      endDate.getTime() - selectedDateRange * 24 * 60 * 60 * 1000,
    );
    setStartDate(newStartDate);
  };

  const location = useLocation();

  const chartData = {
    labels: cardData.slice(0, 10).map((card: {date: any}) => card.date),

    datasets: [
      {
        label: "Pass",
        data: cardData.slice(0, 10).map((card: {pass: any}) => card.pass),
        borderColor: "green",
      },
      {
        label: "Fail",
        data: cardData.slice(0, 10).map((card: {fail: any}) => card.fail),
        borderColor: "red",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Nightly Test Results",
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.dataset.label;
            const value = context.parsed.y;
            const date = context.label;
            if (label === "Pass") {
              return `Pass: ${value} on ${date}`;
            } else {
              return `Fail: ${value} on ${date}`;
            }
          },
        },
        displayColors: true,
        backgroundColor: function (context: any) {
          return context.dataset.borderColor;
        },
        font: {
          size: 14,
        },
        color: {
          opacity: 0.8,
        },
        padding: 10,
      },
    },
  };

  const cumulativeChartsData =
    location.pathname === "/nightlytestview/" ? chartData : chartsData;

  const headerStyling =
    location.pathname !== "/nightlytestview/" && isLoading ? "opacity-50" : "";

  const chartStyling =
    location.pathname === "/nightlytestview/" ? "px-96" : "px-88";

  const fetchGraphData = async () => {
    try {
      setIsLoading(true);
      const headers = {
        Authorization: token,
        Accept: "*/*",
      };
      const response = await axios.get(
        `${apiUrl}/tests/report?type=NIGHTLY&startDate=${startDate.toISOString().split("T")[0]}&endDate=${endDate.toISOString().split("T")[0]}&folderId=${folderId}`,
        {headers},
      );
      const data = response.data.data;

      const passCounts: any = [];
      const failCounts: any = [];
      const dates: any = [];

      data.forEach((item: {pass: any; fail: any; reportDate: any}) => {
        passCounts.push(item.pass);
        failCounts.push(item.fail);
        dates.push(item.reportDate);
      });

      setChartsData({
        labels: dates,
        datasets: [
          {
            label: "Pass",
            data: passCounts,
            borderColor: "green",
          },
          {
            label: "Fail",
            data: failCounts,
            borderColor: "red",
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      alert(
        "Note: The historical graphical representation data is limited to the last 10 days. \nPlease select a date range within this timeframe for accurate results.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location.pathname !== "/nightlytestview/") {
      fetchGraphData();
    }
  }, [startDate, endDate]);

  return (
    <div className={`mt-4 ${headerStyling}`}>
      <br />
      <h2 className="text-xl font-bold mb-4 font-serif">
        Historical Graphical Representation:{" "}
      </h2>
      {location.pathname !== "/nightlytestview/" && (
        <>
          <LoadingIndicator isLoading={isLoading} />
          <div className="flex mb-5">
            <div className="ml-auto w-1/5">
              <select
                value={dateRange}
                onChange={handleDateRangeChange}
                className="bg-gray-200 border border-gray-100 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-serif">
                <option value="7">Last 7 days</option>
                <option value="10">Last 10 days</option>
              </select>
            </div>
          </div>
        </>
      )}
      <div className="relative">
        <div className={`w-full h-96 ${chartStyling}`}>
          <Line
            options={chartOptions}
            data={cumulativeChartsData}
            style={{width: "800px", height: "300px"}}
          />
        </div>
      </div>
    </div>
  );
};

export default Graph;
