import React from "react";
import {IoMdCloseCircleOutline} from "react-icons/io";
import Graph from "../GraphicalComponent/Graph";

interface IPieChartModal {
  isOpen: any;
  onClose: () => void;
  graphData: any;
}

const Modal: React.FC<IPieChartModal> = ({isOpen, onClose, graphData}) => {
  return (
    isOpen && (
      <div
        className="bg-gray-800 bg-opacity-50 z-50 bg-trans justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none"
        style={{zIndex: 1000}}>
        <div className="m-[7rem] p-[35px] mt-[19rem] bg-white rounded-md w-41% relative transition-all duration-5000">
          <span
            className="absolute top-[0px] right-[10px] transition-all text-3xl font-bold text-black hover:text-gray-700 hover:cursor-pointer"
            onClick={onClose}>
            <div className="close mt-[17px]">
              <IoMdCloseCircleOutline />
            </div>
          </span>
          <Graph cardData={graphData} />
        </div>
      </div>
    )
  );
};

export default Modal;
