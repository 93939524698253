import React from "react";
import CardDataStats from "../Libraries/CardDataStats/CardDataStats";
import ArrowUp from "../assets/icons8-up-arrow-26.png";
import ArrowDown from "../assets/icons8-down-arrow-26.png";
import TestIcon from "../assets/icons8-test-100.png";
import Passed from "../assets/icons8-test-passed-100.png";
import Failed from "../assets/icons8-scorecard-100.png";
import Skipped from "../assets/icons8-skip-100.png";

interface CardData {
  total: number;
  pass: number;
  fail: number;
  skip: number;
  missing: number;
}

interface CardComponentProps {
  apiData: CardData;
  setFilter: Function;
  currentFilter: String;
}

const calculateRate = (part: number, total: number) =>
  total ? ((part / total) * 100).toFixed(2) : "0.00";

const CardComponent: React.FC<CardComponentProps> = ({
  apiData,
  setFilter,
  currentFilter,
}) => {
  const {total, pass, fail, skip, missing} = apiData;

  // ToDo: To be implemented later
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-5 2xl:gap-7.5">
      <CardDataStats
        title="TCS"
        total={total}
        rate={100}
        // levelUp
        // icon={ArrowUp}
        cardIcon={TestIcon}
        onClick={() => setFilter("total")}
        selected={currentFilter === "total"}
      />
      <CardDataStats
        title="Passed"
        total={pass}
        rate={parseFloat(calculateRate(pass, total))}
        // levelUp
        // icon={ArrowUp}
        cardIcon={Passed}
        onClick={() => setFilter("pass")}
        selected={currentFilter === "pass"}
      />
      <CardDataStats
        title="Failed"
        total={fail}
        rate={parseFloat(calculateRate(fail, total))}
        // levelUp
        // icon={ArrowUp}
        cardIcon={Failed}
        onClick={() => setFilter("fail")}
        selected={currentFilter === "fail"}
      />
      <CardDataStats
        title="Skipped"
        total={skip}
        rate={parseFloat(calculateRate(skip, total))}
        // levelDown
        // icon={ArrowDown}
        cardIcon={Skipped}
        onClick={() => setFilter("skip")}
        selected={currentFilter === "skip"}
      />
      <CardDataStats
        title="Missing"
        total={missing}
        rate={parseFloat(calculateRate(missing, total))}
        // levelDown
        // icon={ArrowDown}
        cardIcon={Skipped}
        onClick={() => setFilter("missing")}
        selected={currentFilter === "missing"}
      />
    </div>
  );
};

export default CardComponent;
