import { useNavigate } from "react-router-dom";

export const useNavigateToDashboard = (currentPath: string) => {
  const navigate = useNavigate();

  const handleNavigateToDashboard = () => {
    if (currentPath === "/pipelineview/:folderId") {
      navigate("/dashboard");
    }
  };

  return { handleNavigateToDashboard };
};


export const useNavigateToSearchPipeline = () => {
  const navigate = useNavigate();
  return (folderId: string) => {
    navigate(`/pipelineview/${folderId}`, { state: { isSearchResult: true } });
  }
}