import React, { InputHTMLAttributes } from 'react'

const TableSearchBar = (props: InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <div className='flex justify-end pb-2'>
            <input className='p-1 border-2 border-gray-300 outline-none rounded-md max-w-[400px] flex-grow' type="text" {...props} />
        </div>
    )
}

export default TableSearchBar