import React, {useState} from "react";
import "./App.css";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import DynamicPipelineView from "./Pages/DynamicPipeLineViewPages";
import Login from "./Pages/Login";
import ProtectedRoutes from "./CustomHooks/ProtectedRoute";
import NotFoundPage from "./Pages/NotFoundPage";
import NightlyTest from "./Pages/NightlyTest";

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
            path="/dashboard"
            element={<Dashboard setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
            element={<ProtectedRoutes isAuthenticated={isAuthenticated} />}>
            <Route
              path="/nightlytestview"
              element={<NightlyTest setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route
              path="/pipelineview/:folderId"
              element={
                <DynamicPipelineView setIsAuthenticated={setIsAuthenticated} />
              }
            />
          </Route>
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
