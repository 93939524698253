import React from "react";
import {Hourglass} from "react-loader-spinner";

interface ILoadingIndicator {
  isLoading: boolean;
}

const LoadingIndicator: React.FC<ILoadingIndicator> = ({isLoading}) => {
  if (!isLoading) return null;

  return (
    <div className="absolute inset-0 flex justify-center items-center">
      <Hourglass
        visible={true}
        height="100"
        width="100"
        ariaLabel="hourglass-loading"
        wrapperStyle={{}}
        wrapperClass=""
        colors={["black", "black"]}
      />
    </div>
  );
};

export default LoadingIndicator;
