import React, {useEffect, useState} from "react";
import {RxArrowDown} from "react-icons/rx";
import {RxArrowUp} from "react-icons/rx";

import {renderPagination} from "../RenderPagination";
import TableSearchBar from "../TableSearchBar";
import {sortList} from "../../Utils/sortFunction";
import {SortFieldsType} from "../../Types/GeneralTypes";

interface IssueTableProps {
  tableName: string;
  loading: boolean;
  childIssues: any[];
  itemsPerPage?: number;
}

const IssueTable: React.FC<IssueTableProps> = ({
  tableName,
  loading,
  childIssues,
  itemsPerPage = 5,
}) => {
  const [issuesList, setIssuesList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState<string>("");
  const [sortFields, setSortField] = useState<SortFieldsType>({
    id: false,
    name: false,
    total: false,
    pass: false,
    fail: false,
    skip: false,
    missing: false,
  });

  const totalPages = Math.ceil(childIssues.length / itemsPerPage);
  const maxButtonsToShow = 5;

  const jiraUrl = process.env.REACT_APP_JIRA_URL;

  const handlePageChange = (pageNo: number) => {
    setCurrentPage(pageNo);
  };

  const handleSorting = (sortField: string, sortOrder: boolean) => {
    if (sortField) {
      const sorted = sortList(issuesList, sortField, sortOrder);
      setIssuesList(sorted);
    }
  };

  const handleSearchTextChange = (e: any) => {
    const value = e.target.value;
    setSearchText(value);
    if (value.length > 0) {
      const issues = childIssues.filter(
        i =>
          i.name.toLowerCase().includes(value.toLowerCase()) ||
          i.id.toLowerCase().includes(value.toLowerCase()),
      );
      setIssuesList(issues);
    } else {
      setIssuesList(childIssues);
    }
  };

  const handleLinkClick = (key: any) => {
    const jiraLink = `${jiraUrl}?detail=TCS&issueKey=${key}`;
    window.location.href = jiraLink;
  };

  function fieldInSortField(field: any): field is keyof typeof sortFields {
    return Object.keys(sortFields).includes(field);
  }

  const handleFieldSort = (field: string) => {
    if (fieldInSortField(field)) {
      setSortField((prvs: SortFieldsType) => {
        const updatedSortFields = {...prvs};
        updatedSortFields[field] = updatedSortFields[field] ? false : true;
        return updatedSortFields;
      });

      handleSorting(field, sortFields[field]);
    }
  };

  useEffect(() => {
    setIssuesList(
      childIssues.map(item => {
        const updatedItem = {...item};
        Object.keys(updatedItem).forEach(key => {
          if (!updatedItem[key]) updatedItem[key] = 0;
        });
        return updatedItem;
      }),
    );
  }, [childIssues]);

  return (
    <div className="rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 w-[93.2vw]">
      <h4 className="mb-6 text-xl font-semibold text-black font-serif">
        Issues For: {tableName}
      </h4>
      <div>
        {loading ? (
          <div className="px-6 py-24 text-center">
            <div
              className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          <>
            <TableSearchBar
              placeholder="Search by Summary/TCS ID"
              value={searchText}
              onChange={handleSearchTextChange}
            />
            {issuesList.length > 0 ? (
              <>
                <div className="flex flex-col border">
                  <div
                    className="grid grid-cols-3 rounded-sm bg-gray-100 dark:bg-meta-4 sm:grid-cols-7 overflow-x-auto"
                    style={{position: "sticky", top: 0, zIndex: 1}}>
                    <div className="flex items-center p-2.5 xl:p-5">
                      <h5 className="text-sm font-medium uppercase xsm:text-base">
                        TCS ID
                      </h5>
                      <button
                        className="h-fit w-fit"
                        onClick={() => {
                          handleFieldSort("id");
                        }}>
                        {sortFields.id ? <RxArrowDown /> : <RxArrowUp />}
                      </button>
                    </div>
                    <div className="flex items-center p-2.5 text-center xl:p-5">
                      <h5 className="text-sm font-medium uppercase xsm:text-base">
                        Summary
                      </h5>
                      <button
                        className="h-fit w-fit"
                        onClick={() => {
                          handleFieldSort("name");
                        }}>
                        {sortFields.name ? <RxArrowDown /> : <RxArrowUp />}
                      </button>
                    </div>
                    <div className="flex items-center p-2.5 text-center xl:p-5">
                      <h5 className="text-sm font-medium uppercase xsm:text-base">
                        Total Test Cases
                      </h5>
                      <button
                        className="h-fit w-fit"
                        onClick={() => {
                          handleFieldSort("total");
                        }}>
                        {sortFields.total ? <RxArrowDown /> : <RxArrowUp />}
                      </button>
                    </div>
                    <div className="flex items-center p-2.5 text-center xl:p-5">
                      <h5 className="text-sm font-medium uppercase xsm:text-base">
                        Tests Passed
                      </h5>
                      <button
                        className="h-fit w-fit"
                        onClick={() => {
                          handleFieldSort("pass");
                        }}>
                        {sortFields.pass ? <RxArrowDown /> : <RxArrowUp />}
                      </button>
                    </div>
                    <div className="flex items-center p-2.5 text-center xl:p-5">
                      <h5 className="text-sm font-medium uppercase xsm:text-base">
                        Tests Failed
                      </h5>
                      <button
                        className="h-fit w-fit"
                        onClick={() => {
                          handleFieldSort("fail");
                        }}>
                        {sortFields.fail ? <RxArrowDown /> : <RxArrowUp />}
                      </button>
                    </div>
                    <div className="flex items-center p-2.5 text-center xl:p-5">
                      <h5 className="text-sm font-medium uppercase xsm:text-base">
                        Tests Skipped
                      </h5>
                      <button
                        className="h-fit w-fit"
                        onClick={() => {
                          handleFieldSort("skip");
                        }}>
                        {sortFields.skip ? <RxArrowDown /> : <RxArrowUp />}
                      </button>
                    </div>
                    <div className="flex items-center p-2.5 text-center xl:p-5">
                      <h5 className="text-sm font-medium uppercase xsm:text-base">
                        Tests Missing
                      </h5>
                      <button
                        className="h-fit w-fit"
                        onClick={() => {
                          handleFieldSort("missing");
                        }}>
                        {sortFields.missing ? <RxArrowDown /> : <RxArrowUp />}
                      </button>
                    </div>
                  </div>
                  <div
                    className="overflow-y-auto"
                    style={{maxHeight: 300, overflowY: "auto"}}>
                    {issuesList
                      .slice(
                        (currentPage - 1) * itemsPerPage,
                        currentPage * itemsPerPage,
                      )
                      .map((issue: any, key) => (
                        <div
                          className={`grid grid-cols-3 sm:grid-cols-7 border border-stroke dark:border-strokedark`}
                          key={key}>
                          <div className="flex items-center gap-3 p-2.5 xl:p-5">
                            <a
                              href="#"
                              onClick={() => handleLinkClick(issue.id)}
                              className="text-indigo-600 sm:block"
                              target="_blank">
                              {issue?.id || 0}
                            </a>
                          </div>

                          <div className="flex items-center justify-center p-2.5 xl:p-5">
                            <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
                              {issue?.name || 0}
                            </p>
                          </div>

                          <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                            <p className="text-black">{issue?.total || 0}</p>
                          </div>

                          <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                            <p className="text-black">{issue?.pass || 0}</p>
                          </div>

                          <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                            <p className="text-meta-5">{issue?.fail || 0}</p>
                          </div>

                          <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                            <p className="text-meta-5">{issue?.skip || 0}</p>
                          </div>

                          <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                            <p className="text-meta-5">{issue?.missing || 0}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="mt-4 mb-2">
                  {renderPagination({
                    currentPage,
                    totalPages,
                    handlePageChange,
                    maxButtonsToShow,
                  })}
                </div>
              </>
            ) : (
              <div
                className="px-6 py-24 text-center"
                style={{fontWeight: "bold"}}>
                <h1 className="text-bold font-serif">No issues found ☹️ !!</h1>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default IssueTable;
