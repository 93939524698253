import React from "react";

interface PaginationOptions {
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
  maxButtonsToShow: number;
}

export const renderPagination = ({
  currentPage,
  totalPages,
  handlePageChange,
  maxButtonsToShow,
}: PaginationOptions) => {
  const buttons = [];
  const halfButtonsToShow = Math.floor(maxButtonsToShow / 2);

  let startPage = currentPage - halfButtonsToShow;
  let endPage = currentPage + halfButtonsToShow;

  if (startPage <= 0) {
    startPage = 1;
    endPage = maxButtonsToShow;
  }

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = totalPages - maxButtonsToShow + 1;
    if (startPage <= 0) {
      startPage = 1;
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    buttons.push(
      <button
        key={i}
        className={`mx-1 px-3 py-1 rounded-md ${
          i === currentPage
            ? "bg-black text-white"
            : "bg-gray-200 text-gray-700"
        }`}
        onClick={() => handlePageChange(i)}>
        {i}
      </button>,
    );
  }

  if (startPage > 1) {
    buttons.unshift(
      <button key="startEllipsis" className="mx-1 px-3 py-1 rounded-md">
        ...
      </button>,
    );
  }

  if (endPage < totalPages) {
    buttons.push(
      <button key="endEllipsis" className="mx-1 px-3 py-1 rounded-md">
        ...
      </button>,
    );
  }

  return <div>{buttons}</div>;
};
